@use 'drawflow/dist/drawflow.min.css';

:root {
    --dfBackgroundColor: rgba(242, 242, 242, 1);
    --dfBackgroundSize: 0px;
    --dfBackgroundImage: none;
    --dfNodeType: flex;
    --dfNodeTypeFloat: none;
    --dfNodeBackgroundColor: null;
    --dfNodeTextColor: rgba(40, 41, 46, 1);
    --dfNodeBorderSize: 0px;
    --dfNodeBorderColor: rgba(255, 255, 255, 1);
    --dfNodeBorderRadius: 4px;
    --dfNodeMinHeight: 42px;
    --dfNodeMinWidth: 163px;
    --dfNodePaddingTop: 15px;
    --dfNodePaddingBottom: 15px;
    --dfNodeBoxShadowHL: 0px;
    --dfNodeBoxShadowVL: 3px;
    --dfNodeBoxShadowBR: 6px;
    --dfNodeBoxShadowS: 1px;
    --dfNodeBoxShadowColor: rgba(208, 205, 205, 1);

    --dfNodeHoverBackgroundColor: rgba(248, 239, 255, 1);
    --dfNodeHoverTextColor: rgba(40, 41, 46, 1);
    --dfNodeHoverBorderSize: 0px;
    --dfNodeHoverBorderColor: rgba(255, 253, 253, 1);
    --dfNodeHoverBorderRadius: 4px;

    --dfNodeHoverBoxShadowHL: 0px;
    --dfNodeHoverBoxShadowVL: 3px;
    --dfNodeHoverBoxShadowBR: 6px;
    --dfNodeHoverBoxShadowS: 1px;
    --dfNodeHoverBoxShadowColor: rgba(208, 205, 205, 1);

    --dfNodeSelectedBackgroundColor: rgba(236, 236, 244, 1);
    --dfNodeSelectedTextColor: rgba(40, 41, 46, 1);
    --dfNodeSelectedBorderSize: 0px;
    --dfNodeSelectedBorderColor: rgba(255, 253, 253, 1);
    --dfNodeSelectedBorderRadius: 4px;

    --dfNodeSelectedBoxShadowHL: 0px;
    --dfNodeSelectedBoxShadowVL: 3px;
    --dfNodeSelectedBoxShadowBR: 6px;
    --dfNodeSelectedBoxShadowS: 1px;
    --dfNodeSelectedBoxShadowColor: rgba(176, 179, 181, 1);

    --dfInputBackgroundColor: transparent;
    --dfInputBorderSize: 1px;
    --dfInputBorderColor: transparent;
    --dfInputBorderRadius: 30px;
    --dfInputLeft: -10px;
    --dfInputHeight: 12px;
    --dfInputWidth: 12px;

    --dfInputHoverBackgroundColor: rgba(215, 215, 224, 1);
    --dfInputHoverBorderSize: 1px;
    --dfInputHoverBorderColor: rgba(255, 255, 255, 1);
    --dfInputHoverBorderRadius: 30px;

    --dfOutputBackgroundColor: rgba(215, 215, 224, 1);
    --dfOutputBorderSize: 1px;
    --dfOutputBorderColor: rgba(255, 255, 255, 1);
    --dfOutputBorderRadius: 30px;
    --dfOutputRight: 5px;
    --dfOutputHeight: 12px;
    --dfOutputWidth: 12px;

    --dfOutputHoverBackgroundColor: rgba(215, 215, 224, 1);
    --dfOutputHoverBorderSize: 1px;
    --dfOutputHoverBorderColor: rgba(255, 255, 255, 1);
    --dfOutputHoverBorderRadius: 30px;

    --dfLineWidth: 2px;
    --dfLineColor: rgba(215, 215, 224, 1);
    --dfLineHoverColor: rgba(215, 215, 224, 1);
    --dfLineSelectedColor: rgba(215, 215, 224, 1);

    --dfRerouteBorderWidth: 1px;
    --dfRerouteBorderColor: rgba(255, 255, 255, 1);
    --dfRerouteBackgroundColor: rgba(215, 215, 224, 1);

    --dfRerouteHoverBorderWidth: 1px;
    --dfRerouteHoverBorderColor: rgba(255, 255, 255, 1);
    --dfRerouteHoverBackgroundColor: rgba(215, 215, 224, 1);

    --dfDeleteDisplay: block;
    --dfDeleteColor: rgba(41, 40, 40, 1);
    --dfDeleteBackgroundColor: rgba(173, 173, 182, 1);
    --dfDeleteBorderSize: 1px;
    --dfDeleteBorderColor: rgba(255, 255, 255, 1);
    --dfDeleteBorderRadius: 29px;
    --dfDeleteTop: -20px;

    --dfDeleteHoverColor: rgba(41, 40, 40, 1);
    --dfDeleteHoverBackgroundColor: rgba(173, 173, 182, 1);
    --dfDeleteHoverBorderSize: 1px;
    --dfDeleteHoverBorderColor: rgba(255, 255, 255, 1);
    --dfDeleteHoverBorderRadius: 29px;
}

#drawflow {
    background: var(--dfBackgroundColor);
    background-size: var(--dfBackgroundSize) var(--dfBackgroundSize);
    background-image: var(--dfBackgroundImage);
}

.drawflow .drawflow-node {
    display: var(--dfNodeType);
    background: var(--dfNodeBackgroundColor);
    color: var(--dfNodeTextColor);
    border: var(--dfNodeBorderSize) solid var(--dfNodeBorderColor);
    border-radius: var(--dfNodeBorderRadius);
    min-height: var(--dfNodeMinHeight);
    width: auto;
    min-width: var(--dfNodeMinWidth);
    /*   padding-top: var(--dfNodePaddingTop);
    padding-bottom: var(--dfNodePaddingBottom); */
    padding: 0;
    -webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR)
        var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
    box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR)
        var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
    & .drawflow_content_node {
        @apply h-[47px] w-[163px];
    }
}

.drawflow .drawflow-node:hover {
    background: var(--dfNodeHoverBackgroundColor);
    color: var(--dfNodeHoverTextColor);
    border: var(--dfNodeHoverBorderSize) solid var(--dfNodeHoverBorderColor);
    border-radius: var(--dfNodeHoverBorderRadius);
    -webkit-box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL)
        var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
    box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL)
        var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
}

.drawflow .drawflow-node.selected {
    background: var(--dfNodeSelectedBackgroundColor);
    color: var(--dfNodeSelectedTextColor);
    border: var(--dfNodeSelectedBorderSize) solid var(--dfNodeSelectedBorderColor);
    border-radius: var(--dfNodeSelectedBorderRadius);
    -webkit-box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL)
        var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS)
        var(--dfNodeSelectedBoxShadowColor);
    box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL)
        var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS)
        var(--dfNodeSelectedBoxShadowColor);
}

.drawflow .drawflow-node .input {
    left: var(--dfInputLeft);
    background: var(--dfInputBackgroundColor);
    border: var(--dfInputBorderSize) solid var(--dfInputBorderColor);
    border-radius: var(--dfInputBorderRadius);
    height: var(--dfInputHeight);
    width: var(--dfInputWidth);
}

.drawflow .drawflow-node .input:hover {
    background: var(--dfInputHoverBackgroundColor);
    border: var(--dfInputHoverBorderSize) solid var(--dfInputHoverBorderColor);
    border-radius: var(--dfInputHoverBorderRadius);
}

.drawflow .drawflow-node .outputs {
    float: var(--dfNodeTypeFloat);
}

.drawflow .drawflow-node .output {
    right: var(--dfOutputRight);
    background: var(--dfOutputBackgroundColor);
    border: var(--dfOutputBorderSize) solid var(--dfOutputBorderColor);
    border-radius: var(--dfOutputBorderRadius);
    height: var(--dfOutputHeight);
    width: var(--dfOutputWidth);
}

.drawflow .drawflow-node .output:hover {
    background: var(--dfOutputHoverBackgroundColor);
    border: var(--dfOutputHoverBorderSize) solid var(--dfOutputHoverBorderColor);
    border-radius: var(--dfOutputHoverBorderRadius);
}

.drawflow .connection .main-path {
    stroke-width: var(--dfLineWidth);
    stroke: var(--dfLineColor);
}

.drawflow .connection .main-path:hover {
    stroke: var(--dfLineHoverColor);
}

.drawflow .connection .main-path.selected {
    stroke: var(--dfLineSelectedColor);
}

.drawflow .connection .point {
    stroke: var(--dfRerouteBorderColor);
    stroke-width: var(--dfRerouteBorderWidth);
    fill: var(--dfRerouteBackgroundColor);
}

.drawflow .connection .point:hover {
    stroke: var(--dfRerouteHoverBorderColor);
    stroke-width: var(--dfRerouteHoverBorderWidth);
    fill: var(--dfRerouteHoverBackgroundColor);
}

.drawflow-delete {
    display: var(--dfDeleteDisplay);
    color: var(--dfDeleteColor);
    background: var(--dfDeleteBackgroundColor);
    border: var(--dfDeleteBorderSize) solid var(--dfDeleteBorderColor);
    border-radius: var(--dfDeleteBorderRadius);
}

.parent-node .drawflow-delete {
    top: var(--dfDeleteTop);
}

.drawflow-delete:hover {
    color: var(--dfDeleteHoverColor);
    background: var(--dfDeleteHoverBackgroundColor);
    border: var(--dfDeleteHoverBorderSize) solid var(--dfDeleteHoverBorderColor);
    border-radius: var(--dfDeleteHoverBorderRadius);
}
/* .node {
    :has(.node-choice) {
        border: 1px solid red;
        .output {
            border: 1px solid red;
        }
    }
} */

.node-choice .outputs {
    .output {
        &:nth-child(1) {
            @apply bg-twSuccessGreen;
        }
        &:nth-child(2) {
            @apply bg-twErrorRed;
        }
    }
}
.node-question-list,
.node-question-buttons {
    /* &.node__timer {
        .outputs {
            .output {
                &:last-child {
                    //@apply bg-twDarkRed;
                    background-color: #b4076e !important;
                }
            }
        }
    } */
    .outputs {
        .output {
            &:nth-child(1) {
                @apply bg-twRfmDarkPink;
            }
            &:nth-child(2) {
                @apply bg-twRfmDarkBlue;
            }
            &:nth-child(3) {
                @apply bg-twRfmDarkGreen;
            }
            &:nth-child(4) {
                @apply bg-twBlue;
            }
            &:nth-child(5) {
                @apply bg-twOrange;
            }
            &:nth-child(6) {
                @apply bg-twRfmDarkYellow;
            }
            &:nth-child(7) {
                @apply bg-twLightBlue;
            }
            &:nth-child(8) {
                @apply bg-twGreen;
            }
            &:nth-child(9) {
                @apply bg-twPurple;
            }
            &:nth-child(10) {
                @apply bg-twBrown;
            }
        }
    }
}

.node__question-open {
    .outputs {
        .output {
            &:nth-child(2) {
                @apply bg-twDarkRed;
            }
        }
    }
}
.node__timer {
    .outputs {
        .output {
            @apply bg-twDarkRed;
        }
    }
}
