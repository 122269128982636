@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../assets/fonts/helvetica-neue/helveticaneue-light.woff2') format('woff2');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/helvetica-neue/helveticaneue.woff2') format('woff2');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../assets/fonts/helvetica-neue/helveticaneue-medium.woff2') format('woff2');
}
