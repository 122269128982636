$tw-gray-base-text: #000000 !default;
$tw-main-background: #ffffff !default;
//
$tw-row-hover: hsl(240, 25%, 98.5%) !default;
$tw-gray-lightest: #f6f6fa !default;
$tw-gray-lighter: #ececf4 !default;
$tw-gray-light: #d7d7e0 !default;
$tw-gray: #adadb6 !default;
$tw-gray-dark: #6f6f76 !default;
$tw-electric-purple: #8512ba !default;
$tw-mid-purple: #dec7ef !default;
$tw-light-purple: #e8d7f4 !default;
//
$tw-table-commerce: #c89bdf !default;
$tw-table-social: #fba8df !default;
//
$tw-error-red: #fdb1b6 !default;
$tw-bg-error-red: #ffe3e3 !default;
